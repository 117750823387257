import React from "react";

import Layout from '../components/layout';
import SEO from "../components/seo"

import pic01 from '../assets/images/pic01.jpg'

class Homepage extends React.Component {
    render() {
        return (
            <Layout>
                <SEO title="Wiggler | The original mouse jiggler" keywords={[`wiggler`, `jiggler`, `jiggling`, `wiggling`, `mouse`, `awake`]} />

                <section id="one" className="main style1">
                    <div className="grid-wrapper">
                        <div className="col-6">
                            <header className="major">
                                <h2>Tired of having your screen forcibly locked?</h2>
                            </header>
                            <p>
                                Looking for an easier way to keep your screen on and your Skype status active than this? Head to the&nbsp;
                                <a href="https://www.microsoft.com/store/apps/9nblggh2sq2n">Windows Store and try out Wiggler for free</a>.
                                It will be the best decision you will ever make in your entire life.
                            </p>
                        </div>
                        <div className="col-6">
                            <div className="h_iframe" >
                                <iframe title="Youtube video" src="https://www.youtube.com/embed/z_hJUZ-4Ko8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="two" className="main style2">
                    <div className="grid-wrapper">
                        <div className="col-6">
                            <ul className="major-icons">
                            <li><span className="icon style5 major fa-desktop"></span></li>
                            
                                <li><span className="icon style1 major fa-windows"></span></li>
                                <li><span className="icon style4 major fa-cog"></span></li>
                                <li><span className="icon style2 major fa-laptop"></span></li>
                            </ul>
                        </div>
                        <div className="col-6">
                            <header className="major">
                                <h2>Wiggler - The original mouse jiggling app for Windows</h2>
                            </header>
                            <p>Wiggler prevents the screen from locking by signaling mouse interrupts to the OS tricking it into thinking a human is moving the mouse.</p>
                            <p>Useful when doing long software installs where screenlocks may be enforced by your administrator or when you just need to be somewhere else other than at your computer.</p>
                            <p>Skype sessions stay active so you always show as available no matter where in the world you are</p>
                        </div>
                    </div>
                </section>
            </Layout>
        );
    }
}

export default Homepage;