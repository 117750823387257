import React from 'react'

class Header extends React.Component {
    render() {
        return (
            <section id="header">
                <div className="inner">
                    <span className="icon major fa fa-mouse-pointer"></span>
                    <h1>Hi, I'm <strong>Wiggler</strong>, another fine <br />
                    little freebie from the <a href="https://softwaregoodiebag.com">SoftwareGoodiebag</a>.</h1>
                    <p>Wiggler is the original mouse wiggling/jiggling app that prevents your screen from locking on physical <br /> or virtual machines by simulating the movement of the mouse</p>
                    <ul className="actions uniform">
                        <li><a href="https://www.microsoft.com/store/apps/9nblggh2sq2n" className="button">Get it free on the Windows Store</a></li>
                    </ul>
                </div>
            </section>
        )
    }
}

export default Header
